import theme from '@/theme'
import React from 'react'

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
export type BubbleProps = { children: React.ReactNode } & DivProps

const Bubble = (props: BubbleProps) => {
    const { children, style: styleOverrides, ...others } = props
    const style = {
        margin: '20px 0',
        padding: '15px 30px',
        // border: `solid 1px ${theme.colors.border}`,
        // borderRadius: '10px',
        backgroundColor: theme.colors.lightGray,
        overflow: 'hidden',
    }
    return (
        <div style={{ ...style, ...styleOverrides }} {...others}>
            {children}
        </div>
    )
}

export default Bubble
