import React from 'react'
import InfoPageTitle from '@/components/atoms/InfoPageTitle'
import Bubble from '@/components/atoms/Bubble'
import { withLayout } from '@/components/template/Layout'
import styled from 'styled-components'

const Content = styled.div`
    display: grid;
    margin: 30px;

    grid-template-columns: 170px 1fr;
    row-gap: 25px;

    .label {
        color: #707070;
    }
`

const About = () => {
    return (
        <>
            <InfoPageTitle>運営会社について</InfoPageTitle>
            <Bubble>
                <Content>
                    <div className="label">会社名</div>
                    <div>株式会社TBM</div>

                    <div className="label">取締役会長</div>
                    <div>
                        角 祐一郎（日本製紙株式会社 元専務取締役）
                        <br />
                        著書に「紙の科学」
                    </div>

                    <div className="label">代表取締役 CEO</div>
                    <div>山﨑 敦義</div>

                    <div className="label">設立年月日</div>
                    <div>2011年8月30日</div>

                    <div className="label">本社所在地</div>
                    <div>
                        〒100-0006 東京都千代田区有楽町1-2-2 東宝日比谷ビル15F
                        <br />
                        電話番号：03-6268-8915（代表）
                    </div>
                </Content>
            </Bubble>
        </>
    )
}

export default withLayout(About)
