import styled from "styled-components"
import theme from "@/theme"

const InfoPageTitle = styled.h1`
    color: ${theme.colors.black};
    font-size: 20px;
    margin-bottom: -5px;
    margin-top: 15px;
`

export default InfoPageTitle